import React, {useState} from 'react'

import cn from 'classnames'
import ShowToast from "./toast/ShowToast";

function encode(data) {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
        formData.append(key, data[key])
    }
    return formData
}

export default () => {
    const [botField, setBotField] = useState(undefined);
    const handleChange = (e) => {
        setBotField(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target;
        if (!form.email.value || form.email.value.indexOf('@') === -1) {
            ShowToast('warning', 'Please provide valid email!');
            return;
        }
        const data = {
            'form-name': form.getAttribute('name'),
            'fullname': form.fullname.value,
            'email': form.email.value,
            'subject': form.subject.value,
            'message': form.message.value,
        };
        if (botField) {
            data['bot-field'] = botField;
            console.log("You are a bot!");
        }
        fetch('https://beta--naughty-raman-522e4b.netlify.com/', {
            method: 'POST',
            mode: 'no-cors',
            body: encode(data),
        }).then(() => {
            ShowToast('success', 'Thanks for submission!');
            form.reset();
            setBotField(undefined);
        }).catch((error) => {
            ShowToast('success', 'Failed to submit!');
        })
    }

    return (
        <>
            <form name={'ContactUs'} method={"post"} netlify-honeypot={"bot-field"} data-netlify={"true"} onSubmit={handleSubmit}>
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type={"hidden"} name={"form-name"} value={"ContactUs"}/>
                <p hidden={true}>
                    <label>
                        Don’t fill this out: <input name={"bot-field"} onChange={handleChange}/>
                    </label>
                </p>
                <div className={cn('columns is-multiline has-margin-bottom-20')}>
                    <div className={cn('column is-12 is-6-desktop')}>
                        <div className={'field'}>
                            <label className={'label has-text-weight-medium'}>
                                Full Name
                                <input name={'fullname'} required={true} className={'input is-medium'} type={'text'} placeholder={'Ex. Natalie Greenwood'}/>
                            </label>
                        </div>
                    </div>
                    <div className={cn('column is-12 is-6-desktop')}>
                        <div className={'field'}>
                            <label className={'label has-text-weight-medium'}>
                                Email
                                <input name={'email'} required={true} className={'input is-medium'} type={'email'} placeholder={'Ex. example@gmail.com'}/>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={cn('columns is-multiline has-margin-bottom-20')}>
                    <div className={cn('column is-12')}>
                        <div className={'field'}>
                            <label className={'label has-text-weight-medium'}>
                                Subject
                                <input name={'subject'} required={true} className={'input is-medium'} type={'text'} placeholder={'Subject'}/>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={cn('columns')}>
                    <div className={cn('column')}>
                        <div className={'field'}>
                            <label className={'label has-text-weight-medium'}>
                                Your Message
                                <textarea name={'message'} required={true} className={'textarea'} rows={"10"} placeholder={'Message'}/>
                            </label>
                        </div>
                    </div>
                </div>
                <button type={'submit'} style={{width: '100%'}}
                        className={'button has-text-weight-bold is-rounded button-green has-padding-top-15 has-padding-bottom-15 is-full-height'}>SEND
                </button>
            </form>
        </>
    )
}
