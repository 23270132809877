import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import ContactUsForm from "../components/ContactUsForm";
import FooterDivider from "../components/FooterDivider";
import GenericHeader from "../components/header_section/GenericHeader";
import Container from "../components/layouts/Container";
import RowItem from "../components/layouts/RowItem";
import Row from "../components/layouts/Row";
import styled from "styled-components";

const ContactRowItem = styled(RowItem)`
  margin: ${90 / 16}rem auto;
`

const ContactPage = ({data}) => {
  const {markdownRemark: post} = data;
  return (
    <Layout local_title={'Contact - Cypress'}
            meta_image={post.frontmatter.title_image.childImageSharp.fluid.src}
            meta_image_width={post.frontmatter.title_image.childImageSharp.fluid.presentationWidth}
            meta_image_height={post.frontmatter.title_image.childImageSharp.fluid.presentationHeight}
            meta_keyword={`Cypress contact, contact us page, app development company contact, IT company contacts, Cypress email`}
            meta_description={`Contact Cypress - an innovative mobile app development company that is open to your ideas and ready to make your digital dreams come true.`}>
      <GenericHeader title={'Getting in Touch is Easy!'}
                     subtitle={'Cypress is open to your suggestions. Share your ideas, ask questions, and we’ll return to you within 2 business days'}
                     title_image={post.frontmatter.title_image}
                     title_image_mobile={post.frontmatter.title_image_mobile}/>

      <Container>
        <Row>
          <ContactRowItem maxWidth={1037}>
            <ContactUsForm/>
          </ContactRowItem>
        </Row>
      </Container>
      <FooterDivider marginTop={70} marginBottom={50}/>
      <br/>
    </Layout>
  )
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        title_image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 916, srcSetBreakpoints: [749, 916], sizes: "(max-width: 1366px) calc(749px), (min-width: 1367px) calc(916px), 100vw") {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        title_image_mobile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1024, srcSetBreakpoints: [1024]) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;
