import PropTypes from "prop-types";
import styled from "styled-components";

const FooterDivider = styled.hr`
    background-color: #97979761;
    border: none;
    display: block;
    height: 1px;
    margin-top: ${props => (props.marginTop || 0) / 16}rem;
    margin-bottom: ${props => (props.marginBottom || 0) / 16}rem;
`

FooterDivider.propTypes = {
    className: PropTypes.string,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number
}

export default FooterDivider;